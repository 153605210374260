<template>
  <section class="batch-solutions" id="batch-solutions">
    <div class="batch-solutions__container container container__mini">
      <router-link :to="{path: slides.slug}" v-for="slides in data.slice(0, 5)" :key="slides.id" class="batch-solutions__item">
        <div class="batch-solutions__img">
          <v-lazy-image
            v-if="slides.icon"
            :src="slides.icon.url"
            rel="preload"
            width="536"
            height="309"
            alt="solutions"
          />
        </div>
        <div class="batch-solutions__text">
          <h2 class="batch-solutions__title"><pre>{{slides.name}}</pre></h2>
          <p class="batch-solutions__descr"><pre>{{slides.placeholder}}</pre></p>
        </div>
      </router-link>
    </div>
  </section>
</template>

<script>
import VLazyImage from "v-lazy-image";

export default {
  components: {
    VLazyImage,
  },
  props: {
    url: {
      type: String,
      default: process.env.VUE_APP_API,
    },
    data: {
      type: Array,
      required: true,
    },
  },
  computed: {
    iconUrl(icon) {
      return icon.url;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/styles/_mixins.scss";

.batch-solutions {
  padding-right: 20px;

  .batch-solutions-page & {
    @include property("padding-bottom", 100, 10);
    @include property("padding-top", 100, 50);
  }

  .about-company__page & {
    @include property("margin-bottom", 170, 40);
  }

  &__container {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    @media (max-width: 575.98px) {
      justify-content: center;
    }
  }

  &__item {
    position: relative;
    margin-left: 60px;

    &:nth-child(-n + 2) {
      flex: 1 1 calc(50% - 60px);
      margin-bottom: 90px;
      max-width: 536px;
    }

    &:nth-child(n + 3) {
      flex: 1 1 calc(33.333% - 60px);
      max-width: 335px;
    }

    &::before {
      content: "";
      position: absolute;
      top: -25px;
      left: 20px;
      width: 100%;
      height: 100%;
      border: 1px solid #f9b80e;
      z-index: 2;
    }

    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: #000000;
      opacity: 0.19;
      z-index: 3;
    }

    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    @media (min-width: 1024px) {
      &:nth-of-type(-n + 2) {
        .batch-solutions__img {
          padding-bottom: 57.65%;
        }
      }

      &:nth-of-type(n + 3) {
        .batch-solutions__img {
          padding-bottom: 85.6%;
        }
      }
    }

    @media (max-width: 1023.98px) {
      flex: 0 1
        calc(50% - calc(20px + (45 - 20) * ((100vw - 768px) / (1024 - 768)))) !important;
      max-width: 100% !important;
      margin-bottom: 60px !important;

      &:nth-child(odd) {
        margin-left: calc(
          0px + (30 - 0) * ((100vw - 768px) / (1024 - 768))
        ) !important;
      }

      &:nth-child(even) {
        margin-left: calc(
          0px + (60 - 0) * ((100vw - 768px) / (1024 - 768))
        ) !important;
      }
    }

    @media (max-width: 767.98px) {
      flex: 0 1 calc(50% - 20px) !important;
      margin-bottom: 40px !important;

      &:nth-child(odd) {
        margin-left: 0 !important;
      }

      &:nth-child(even) {
        margin-left: 0 !important;
      }

      &::before {
        content: "";
        position: absolute;
        top: -15px;
        left: 15px;
        width: 100%;
        height: 100%;
        border: 1px solid #f9b80e;
        z-index: 2;
      }
    }

    @media (max-width: 575.98px) {
      flex: 0 1 100% !important;
      max-width: 390px !important;
    }

    @media (hover: hover) {
      &:hover {
        .batch-solutions__descr {
          opacity: 1;
          visibility: visible;
        }
      }
    }
  }

  &__img {
    position: relative;
    z-index: 1;

    @media (max-width: 1023.98px) {
      padding-bottom: 85.6%;
    }
  }

  &__text {
    position: absolute;
    z-index: 9;
    left: 0;
    @include property("top", 40, 30, true, 1400, 1024);
  }

  &__title {
    position: relative;
    @include property("left", -60, -30, true, 1270, 1024);
    display: inline-block;
    padding: 5px 30px 5px 15px;
    font-weight: 700;
    max-width: 340px;
    @include property("font-size", 32, 21, true, 1400, 1024);
    background: #f9b80e;
    text-transform: uppercase;

    @media (max-width: 1023.98px) {
      font-size: 32px;
      padding: 5px 15px;
      left: calc(0px + (-30 - 0) * ((100vw - 768px) / (1024 - 768)));
    }

    @media (max-width: 768px) {
      left: 0;
    }

    @media (min-width: 576px) and (max-width: 768px) {
      font-size: calc(21px + (32 - 21) * ((100vw - 576px) / (768 - 576)));
    }

    @media (max-width: 576px) {
      font-size: 21px;
    }
  }

  &__descr {
    padding: 5px;
    font-weight: 700;
    letter-spacing: 0.48px;
    background: #f9b80e;
    margin-top: 20px;
    @include property("font-size", 24, 18, true, 1400, 1024);
    transition: all 0.3s linear;

    @media (hover: hover) {
      opacity: 0;
      visibility: hidden;
    }

    @media (max-width: 1023.98px) {
      background: rgba(#f9b80e, 0.7);
      font-size: 20px;
    }

    @media (min-width: 576px) and (max-width: 768px) {
      font-size: calc(16px + (20 - 16) * ((100vw - 576px) / (768 - 576)));
    }

    @media (max-width: 576px) {
      font-size: 16px;
    }
  }
}
</style>
